import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createNewPassword } from '../../redux/actions/auth';
import IsLoadingHOC from '../IsLoadingHOC';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import WarningPopup from './WarningPopup';

function CreatePassword(props) {
    const { setLoading } = props
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [model, setModel] = useState(false)


    const formik = useFormik({
        initialValues: {
            password: "",
            confirm_password: ""
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required("This field is required")
                .min(5, 'Password must be at least 5 characters long!'),

            confirm_password: Yup.string()
                .required("This field is required")
                .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),

        }),
        onSubmit: async values => {
            let fieldValues = values
            const token = params.token || ''
            setLoading(true)
            await dispatch(createNewPassword(token, fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == '1') {
                            toast.success('Password has been created successfully')
                            formik.resetForm();
                            history.push('/RSVP');
                            handleToggleModal();
                        } else {
                            const errorMsg = response.data?.error[0] || response.message
                            toast.error(errorMsg)
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                    }
                )
        },
    });

    const handleToggleModal = () => {
        setModel(!model)
    }

    return (
        <>
            <div class="container mb-5 mt-5 pb-5 pt-4">
                <div class="col-md-6 mx-auto">
                    <div class="header-title">
                        <h3 class="fw-normal mb-5">Create your password</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mx-auto">
                        <div class="myform form shadow p-5 mb-5 bg-white rounded">
                            <form onSubmit={formik.handleSubmit} >
                                <div class="form-group">
                                    <label className="form-label">Password*</label>
                                    <input type="password" class="form-control my-input" placeholder="Enter password"
                                        required
                                        name="password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.password && formik.errors.password ? (<span className="text-danger " >{formik.errors.password}</span>) : null}
                                </div>
                                <div class="form-group">
                                    <label className="form-label">Confirm Password*</label>
                                    <input type="password" class="form-control my-input" placeholder="Enter confirm password"
                                        required
                                        name="confirm_password"
                                        onChange={formik.handleChange}
                                        value={formik.values.confirm_password}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.confirm_password && formik.errors.confirm_password ? (<span className="text-danger">{formik.errors.confirm_password}</span>) : null}
                                </div>
                                <div class="text-left">
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {model && <WarningPopup model={model} handleHidePopup={handleToggleModal} />}
        </>
    )
}
export default IsLoadingHOC(CreatePassword)
