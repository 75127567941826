import React, { useState, useEffect } from 'react';
import IsLoadingHOC from '../IsLoadingHOC';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserRoleValidate, validateProduction } from '../../redux/actions/events';
import { getUserDetails } from '../../redux/actions/user';
import { toast } from 'react-toastify';
import moment from "moment";


function ValidateProduction(props) {
    const { setLoading, detailsData, userId } = props
    const dispatch = useDispatch();
    const { user, token } = useSelector(state => state.auth);
    const [isAccepted, setAccepted] = useState(false)
    const [userDetails, setUserDetails] = useState({})


    useEffect(() => {
        getUserDetailsData()
    }, [])

    const getUserDetailsData = async () => {
        setLoading(true)
        await dispatch(getUserDetails(userId))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        setUserDetails(response.data.user)
                    } else {
                        toast.error(response.data?.message)
                    }
                },
                (error) => {
                    toast.error(error.response.data?.message)
                    setLoading(false);
                }
            )
    }


    const handleSUbmit = async () => {
        const payload = { user_id: user.id }
        setLoading(true)
        await dispatch(checkUserRoleValidate(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status == '1') {
                        handleValidateProduction();
                    } else {
                        toast.error(response.message)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
    }

    const handleValidateProduction = async () => {
        const payload = { user_id: userId, event_id: detailsData.id }
        setLoading(true)
        await dispatch(validateProduction(payload))
            .then(
                response => {
                    setLoading(false)
                    if (response.status == '1') {
                        toast.success('User has been validated')
                    } else {
                        toast.warn(response.message)
                    }
                },
                error => {
                    setLoading(false)
                    toast.error(error.response.data?.message)
                }
            )
    }

    const unAuthorized = () => {
        toast.warn(`Please Login first`)
    }

    return (
        <>
            <div className="col-lg-4">
                <div className="rightWrap">
                    <div className="rightWrapinIn">
                        <div className="sidebarMain">
                            <h3 className="sidebarTitle sec_title">Please check whether this user is valid or invalid</h3>
                            <div className='user--details--title'>
                                <h3>User Details</h3>
                            </div>
                            <div className='user--details--show'>
                                <div className='user--details--item'>
                                    <span>User name:</span>
                                    <span>{userDetails.name || 'Not available'}</span>
                                </div>
                                <div className='user--details--item'>
                                    <span>Email address :</span>
                                    <span>{userDetails.email || 'Not available'}</span>
                                </div>
                                <div className='user--details--item'>
                                    <span>User phone :</span>
                                    <span>{userDetails.phone || 'Not available'}</span>
                                </div>
                                <div className='user--details--item'>
                                    <span>Date of birth :</span>
                                    <span>{userDetails.dob ? moment(userDetails.dob).format("MMMM Do YYYY") : 'Not available'}</span>
                                </div>
                                <div className='user--details--item'>
                                    <span>Instagram handle :</span>
                                    <span>{userDetails.instagram_name || 'Not available'}</span>
                                </div>
                                <div className="form-group form-check">
                                    <input type="checkbox" onChange={() => setAccepted(!isAccepted)} className="form-check-input" />
                                    <span className="form-check-label">{ }I accept that this user is valid for this production.</span>
                                </div>
                            </div>
                            <div className='user-valid-btn'>
                                {token ?
                                    <button className="btn btn-primary" disabled={!isAccepted} onClick={handleSUbmit} >Validate user</button>
                                    : <button className="btn btn-primary" disabled={detailsData?.status === "0"} onClick={unAuthorized} data-toggle="modal" data-target="#loginForm">Validate user</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default IsLoadingHOC(ValidateProduction)
