import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import banner from '../../assets/logo-bannerSection.png'
import IsLoadingHOC from '../IsLoadingHOC'
import axios from 'axios';
import advertising_banner from '../../assets/advertising_banner.jpg'
import { VIEMO_VIDEO_API_URL } from '../../config';
import ReactPlayer from 'react-player';
import VimeoRelatedVideos from './VimeoRelatedVideos';
import ScrollToTop from '../common/ScrollToTop';

function PlayVimeoVideo(props) {
    var { slug } = useParams();
    const { setLoading } = props;
    const [recentVideo, setRecentVideo] = useState({})
    const [realTimeVideo, setRealTimeVideo] = useState("")
    const Token = process.env.REACT_APP_VIMEO_TOKEN;
    useEffect(async () => {
        setLoading(true);
        const response = await axios.get(`${VIEMO_VIDEO_API_URL}/${slug}`,
            {
                headers: {
                    Authorization: `bearer ${Token}`
                }
            })
        if (response) {
            setRealTimeVideo(response.data)
            setRecentVideo(response.data)
            setLoading(false);
        }
    }, [slug]);
    return (
        <>
            <ScrollToTop />
            <div>
                <section className="logoBanner">
                    <div className="container">
                        <div className="logoBanner--wraper">
                            <img src={banner} alt="" />
                        </div>
                    </div>
                </section>
                <section className="wrap_con">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="leftWrap">
                                    <div className="leftWrapin">
                                        <div className="articleMain">
                                            <div className="articleMain--thumbnail vidThumb">
                                                {slug &&
                                                    <ReactPlayer url={realTimeVideo.player_embed_url} className="player__video" controls />
                                                }
                                            </div>
                                            <div>
                                                <h2 className="articleMain--title" >{realTimeVideo.name}</h2>
                                            </div>
                                            <div className="articleMain--content">

                                                <div className="articleMain--description">
                                                    <p dangerouslySetInnerHTML={{ __html: realTimeVideo.description }}></p><br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="rightWrap">
                                    <div className="rightWrapinIn">
                                        <div className="sidebarMain">
                                            <h3 className="sidebarTitle sec_title">Related Videos</h3>
                                            <div className="relatedVideos">
                                                <VimeoRelatedVideos />
                                            </div>
                                        </div>
                                        <div className="sidebarMain">
                                            <h3 className="sidebarTitle sec_title">Network Sponsors</h3>
                                            <div >
                                                <img src={advertising_banner} alt="Item" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default IsLoadingHOC(PlayVimeoVideo)