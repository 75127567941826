import { combineReducers } from 'redux'
import auth from "./auth";
import users from "./users";
import posts from './posts'
import news from './news'
import video from './video'
import event from './events'
export default combineReducers({
     auth,
     users,
     posts,
     news,
     video,
     event
})