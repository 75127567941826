
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = useSelector(state => state.auth.token)

    if (!token) {
        toast.warn('Please login first')
    }
    return (
        <Route {...rest} render={(props) => (
            token
                ? <Component {...props} />
                : <Redirect to='/' />
        )}
        />
    )
}
export default PrivateRoute