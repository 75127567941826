import { withoutAuthAxios } from '../../config'


export const register = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/api/register-users", data)
            .then(
                response => {
                    resolve(response.data)
                    if (response.data.status == 1) {
                        const jwtToken = response.data.data.token || null
                        const userDetails = response.data.data.user || {}
                        _dispatch({ type: "SAVE_TOKEN", payload: jwtToken });
                        _dispatch({ type: "USER_DATA_UPDATE", payload: userDetails });
                    }
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorrrr", error);
                }
            )
    })
}

export const createNewPassword = (token, data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post(`/api/user-password-with-login/${token}`, data)
            .then(
                response => {
                    resolve(response.data)
                    if (response.data.status == 1) {
                        const jwtToken = response.data.token || null
                        const userDetails = response.data.user || {}
                        _dispatch({ type: "SAVE_TOKEN", payload: jwtToken });
                        _dispatch({ type: "USER_DATA_UPDATE", payload: userDetails });
                    }
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Errorr", error);
                }
            )
    })
}

export const ressetPassword = (token, data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post(`/api/user-reset-password/${token}`, data)
            .then(
                response => {
                    resolve(response.data)
                    if (response.data.status == 1) {
                        const jwtToken = response.data.token || null
                        const userDetails = response.data.user || {}
                        _dispatch({ type: "SAVE_TOKEN", payload: jwtToken });
                        _dispatch({ type: "USER_DATA_UPDATE", payload: userDetails });
                    }
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Errorr", error);
                }
            )
    })
}

export const login = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/api/login", data)
            .then(
                response => {
                    resolve(response.data)
                    if (response.data.status == 1) {
                        const jwtToken = response.data.token || null
                        const userDetails = response.data.user || {}
                        _dispatch({ type: "SAVE_TOKEN", payload: jwtToken });
                        _dispatch({ type: "USER_DATA_UPDATE", payload: userDetails });
                    }
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Errorr", error);
                }
            )
    })
}

export const forgotpassword = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/api/send-email", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("Errorr", error);
                }
            )
    })
}

export const logOut = () => _dispatch => {
    return (
        _dispatch({ type: "LOGOUT" })
    )
}


export const sendOtpApi = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/api/send_verification_code", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorr", error);
                }
            )
    })
}

export const verifyOtpApi = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/api/check_verification_code", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("error", error);
                }
            )
    })
}

export const sendLoginOtpApi = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/api/send_login_verification_code", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorr", error);
                }
            )
    })
}

export const verifyLoginOtpApi = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/api/check_login_verification_code", data)
            .then(
                response => {
                    resolve(response.data)
                    if (response.data.status == "1") {
                        const jwtToken = response.data.token || null
                        const userDetails = response.data.user || {}
                        _dispatch({ type: "SAVE_TOKEN", payload: jwtToken });
                        _dispatch({ type: "USER_DATA_UPDATE", payload: userDetails });
                    }

                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("error", error);
                }
            )
    })
}

export const checkExistUserApi = data => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await withoutAuthAxios().post("/api/check_existing_credentials", data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorr", error);
                }
            )
    })
}

