import { authAxios } from '../../config'

export const getEventsList = (pageNumber,limit) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().get(`/api/event-list?page=${pageNumber}&limit=${limit}`)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorrrr", error);
                }
            )
    })
}

export const getEventDetail = (id) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/api/event-show/${id}`)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorrrr", error);
                }
            )
    })
}

export const getEventDetailById = (id) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/api/event-showbyid/${id}`)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorrrr", error);
                }
            )
    })
}

export const setProductionID = (data) => _dispatch => {
    return (
        _dispatch({ type: "SAVE_EVENT_ID" ,payload: data })
    )
}

export const joinTheEvent = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/api/store-event-join-list`,data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorrrr", error);
                }
            )
    })
}

export const getJointEventsList = (pageNumber,limit) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().get(`/api/event-join-list?page=${pageNumber}&limit=${limit}`)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorrrr", error);
                }
            )
    })
}

export const checkUserRoleValidate = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/api/validate-user`,data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorrrr", error);
                }
            )
    })
}

export const validateProduction = (data) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().post(`/api/validate-production`,data)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorrrr", error);
                }
            )
    })
}

export const getPrivacyPolicy = (id) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().get(`/api/get-production-privacy-policy/${id}`)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorrrr", error);
                }
            )
    })
}

export const getBannerImage = (id) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().get(`/api/banner-image`)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorrrr", error);
                }
            )
    })
}


