import { authAxios } from '../../config'


export const getUserJoinedList = (id, pageNumber, limit) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().get(`/api/user-join-list/${id}?page=${pageNumber}&limit=${limit}`)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorrrr", error);
                }
            )
    })
}

export const getUserDetails = (id) => async _dispatch => {
    return new Promise(async (resolve, reject) => {
        await authAxios().get(`/api/user-detail-show/${id}`)
            .then(
                response => {
                    resolve(response.data)
                },
                error => {
                    reject(error)
                }
            )
            .catch(
                error => {
                    console.log("errorrrr", error);
                }
            )
    })
}
