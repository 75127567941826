import React, { useState } from 'react';
import { login, sendLoginOtpApi, verifyLoginOtpApi } from '../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import IsLoadingHOC from '../IsLoadingHOC';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ConfirmJoinModel from '../Production/ConfirmJoinModel';
import { countryCode, phoneRegExp } from './constants';
import OtpInput from 'react-otp-input';



function LoginForm(props) {
    const { isLoading, setLoading } = props
    const dispatch = useDispatch();
    const { event_id } = useSelector(state => state.event)
    const [joinmodel, setJoinModel] = useState(false)
    const [loginWithOtp, setLoginWithOtp] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [showOtp, setShowOtp] = useState(false)
    const [otp, setOtp] = useState("")


    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        onSubmit: async values => {
            let fieldValues = values
            setLoading(true)
            await dispatch(login(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.success === true) {
                            const user_id = response.user.id
                            toast.success('Logged in successfully!')
                            formik.resetForm();
                            hideModel();
                            // if (event_id && user_id) {
                            //     handleJoinModel();
                            // }
                        } else {
                            toast.error(response.message)
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data.message)
                    }
                )
                .catch(error => console.log(error))
        },
    });

    const hideModel = () => {
        let login__modal = document.getElementById("loginForm");
        let login__overlay = document.getElementsByClassName("modal-backdrop")[0];
        login__modal.classList.remove("show");
        login__overlay.classList.remove("show");
        login__overlay.remove();
        login__modal.style.display = "none";
        document.getElementsByTagName("body")[0].classList.remove("modal-open");
    }

    const handleJoinModel = () => {
        setJoinModel(!joinmodel)
    }


    const handleSendOtp = async () => {
        if (!phoneNumber) {
            toast.error("Phone number is required!")
        }
        else if (!phoneRegExp.test(phoneNumber)) {
            toast.error("Invalid Phone number!")
        }
        else {
            let payload = {}
            let phone_number = phoneNumber.toString();
            if (phone_number[0] !== countryCode) {
                payload.phone = `${countryCode}${phone_number}`;
            }
            setLoading(true)
            await dispatch(sendLoginOtpApi(payload))
                .then(
                    response => {
                        setLoading(false);
                        if (response.status == "1") {
                            const toastMsg = response.message || response?.data?.message
                            toast.success(toastMsg)
                            setShowOtp(true)
                        } else {
                            setShowOtp(false)
                            const error = response.message || response?.data?.error[0] || 'Somthing went wrong'
                            toast.error(error)
                        }
                    },
                    (error) => {
                        setLoading(false);
                        setShowOtp(false)
                        const errMsg =  error.response?.data?.message || error.response?.data?.data?.error[0] || 'Somthing went wrong'
                        toast.error(errMsg)
                    }
                )
                .catch(error => {
                    setLoading(false);
                    setShowOtp(false)
                    const errMsg =  error.response?.data?.message || error.response?.data?.data?.error[0] || 'Somthing went wrong'
                    toast.error(errMsg)
                })
        }
    }

    const verifyOtp = async () => {
        let payload = { code: otp }
        let phone_number = phoneNumber.toString();
        if (phone_number[0] !== countryCode) {
            payload.phone = `${countryCode}${phone_number}`;
        }
        setLoading(true)
        await dispatch(verifyLoginOtpApi(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        toast.success(response.message)
                        setLoginWithOtp(false)
                        setShowOtp(false)
                        setOtp("")
                        hideModel();
                    } else {
                        const error = response.message || response?.data?.error[0] || 'Somthing went wrong'
                        toast.error(error)
                    }
                },
                (error) => {
                    setLoading(false);
                    const errMsg = error.response?.data?.message || 'Somthing went wrong'
                    toast.error(errMsg)
                }
            )
            .catch(error => {
                setLoading(false);
                const errMsg = error.response?.data?.message || 'Somthing went wrong'
                toast.error(errMsg)
            })
    }

    return (
        <>
            <div className="modal modalTwo common_form fade" id="loginForm" role="dialog" aria-labelledby="loginFormTitle"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="login_Sign">
                                <div className="loginSign_right">
                                    <div className="loginSign_wraper">
                                        <h2 className="font50 sec_title">Cannabis Capitol Account login</h2>

                                        <form className="form_logSign">
                                            {!loginWithOtp &&
                                                <>
                                                    <div className="form-group">
                                                        <label>Email*</label>
                                                        <input className="form-control" type="email" placeholder="Email ID"
                                                            required
                                                            name="email"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.email}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Password*</label>
                                                        <input className="form-control" type='password' placeholder="Password"
                                                            required
                                                            name="password"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.password}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                </>
                                            }

                                            {loginWithOtp && !showOtp &&
                                                <div className="form-group">
                                                    <label>Phone Number*</label>
                                                    <div className="input-group">
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">{countryCode}</span>
                                                        </div>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Enter the Phone"
                                                            required
                                                            name="phone"
                                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                                            value={phoneNumber}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {showOtp && loginWithOtp &&
                                                <div className="form-group">
                                                    <label>OTP Verification</label>
                                                    <OtpInput
                                                        inputType="number"
                                                        value={otp}
                                                        onChange={(e) => setOtp(e)}
                                                        numInputs={6}
                                                        renderSeparator={<span style={{ width: "5px" }}></span>}
                                                        renderInput={(props) => <input {...props} />}
                                                        shouldAutoFocus={true}
                                                        containerStyle={{
                                                            justifyContent: "center",
                                                            marginTop: "0em",
                                                        }}
                                                        inputStyle={{
                                                            border: "1px solid #23945f",
                                                            borderRadius: "8px",
                                                            width: "10%",
                                                            height: "35px",
                                                            fontSize: "16px",
                                                            color: "black",
                                                            fontWeight: "400",
                                                            caretColor: "blue",
                                                            margin: "20px 8px 0 8px",
                                                        }}
                                                        focusStyle={{
                                                            border: "1px solid #23945f",
                                                            outline: "none",
                                                        }}
                                                    />
                                                </div>
                                            }

                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input className="form-check-input cursor-pointer" type="checkbox"
                                                        id="check1"
                                                        onChange={(e) => setLoginWithOtp(e.target.checked)}
                                                        checked={loginWithOtp}

                                                    />Login with OTP
                                                </label>
                                            </div>

                                            <div className='text-center'>
                                                {!loginWithOtp &&
                                                    <button disabled={isLoading} type="button" onClick={formik.handleSubmit} className="btn form_logSignSubmit text-center">
                                                        {isLoading ? 'Processing' : 'Login'}
                                                    </button>
                                                }
                                                {loginWithOtp && !showOtp &&
                                                    <button disabled={isLoading} type="button" onClick={handleSendOtp} className="btn form_logSignSubmit text-center">
                                                        {isLoading ? 'Processing' : 'Send OTP'}
                                                    </button>
                                                }

                                                {showOtp && loginWithOtp &&
                                                    <button disabled={otp.length < 6 || isLoading} type="button" onClick={verifyOtp} className="btn form_logSignSubmit text-center">
                                                        {isLoading ? 'Processing' : 'Verify OTP'}
                                                    </button>
                                                }
                                            </div>

                                            {showOtp && loginWithOtp &&
                                                <div className="d-flex justify-content-center text-center">
                                                    <p style={{ textAlign: "center", margin: "auto 1px  auto auto" }}>
                                                        Didn't recieve code ?
                                                    </p>
                                                    <button
                                                        disabled={isLoading}
                                                        style={{
                                                            backgroundColor: "transparent",
                                                            border: "none",
                                                            display: "block",
                                                            margin: " auto auto  auto 0px",
                                                            color: "#F6A94A",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={handleSendOtp}
                                                    >
                                                        Resend OTP
                                                    </button>
                                                </div>
                                            }

                                        </form>
                                        {!loginWithOtp &&
                                            < div >
                                                <h5>Don't have an account? &nbsp;<button type="button" className="close" data-dismiss="modal" data-toggle="modal" data-target="#signupForm">Sign Up Here</button></h5>
                                                <div className='text-center'>
                                                    <h5><button type="button" className="close" data-dismiss="modal" data-toggle="modal" data-target="#forgotPass">Forgot Password?</button></h5>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {joinmodel && <ConfirmJoinModel model={joinmodel} handleModel={handleJoinModel} />
            }
        </>
    )
}
export default IsLoadingHOC(LoginForm)
