import React, { useEffect, useState } from 'react';
import defaultEvent from '../../assets/event.jpg';
import IsLoadingHOC from '../IsLoadingHOC';
import banner from '../../assets/logo-bannerSection.png';
import { useParams } from 'react-router-dom';
import ScrollToTop from '../common/ScrollToTop';
import { useDispatch } from 'react-redux';
import { getEventDetailById } from '../../redux/actions/events';


function JoinedProductionDetails(props) {
    const { setLoading } = props
    const params = useParams();
    const [detailsData, setDetailsData] = useState({})
    const dispatch = useDispatch();


    useEffect(() => {
        getProductionDetailData()
    }, [])

    const getProductionDetailData = async () => {
        setLoading(true)
        await dispatch(getEventDetailById(params.id))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setDetailsData(response.data.event)
                    }
                },
                (error) => {
                    console.log(error)
                    setLoading(false);
                }
            )
    }

    return (
        <div>
            <ScrollToTop />
            <section className="logoBanner">
                <div className="container">
                    <div className="logoBanner--wraper">
                        <img src={banner} alt="" />
                    </div>
                </div>
            </section>
            <section className="wrap_con">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="leftWrap">
                                <div className="leftWrapin">
                                    <div className="articleMain">
                                        <h2 className="articleMain--title">{detailsData.name}</h2>
                                        {/* <h3 className="articleMain--subTitle">Sub Title</h3> */}
                                        <div className="articleMain--info">
                                            <span className="articleMain--type">Production</span>
                                            <span className="articleMain--date">{(() => {
                                                var d = new Date(detailsData?.created_at)
                                                return (d.toLocaleString('default', { month: 'long' }) + (d.toUTCString().substr(4, 3)) + ", " + (d.toUTCString().substr(12, 5)))
                                            })()}</span>
                                        </div>
                                        <div className="articleMain--content">
                                            <div className=" col articleMain--thumbnail">
                                                {detailsData && detailsData.image_path && <img src={detailsData.image_path} alt="new" />}
                                            </div>
                                            <div className="articleMain--description">
                                                <p dangerouslySetInnerHTML={{ __html: detailsData?.discription }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default IsLoadingHOC(JoinedProductionDetails)
