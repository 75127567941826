import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logOut } from '../../redux/actions/auth';
import {useHistory} from 'react-router-dom';

function SessionExpired() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [sessionModel, setSessionModel] = useState(true)

    const logoutAction = () => {
        dispatch(logOut())
        setSessionModel(false)
       // history.push('/')
    }

    return (
        <div id="myModal" className={`modal fade ${sessionModel ? "show" : "hide"}`} style={{ display: sessionModel ? "block" : "none" }}>
            <div className="modal-dialog session-out-model">
                <div className="modal-content">
                    <div className="modal-header justify-content-center">
                        <h4 className="modal-title text-center">Alert</h4>
                    </div>
                    <div className="modal-body">
                        <h5>Your session has been expired. Please login again</h5>
                    </div>
                    <div className="modal-footer">
                        <button type="button"onClick={logoutAction} className="btn btn-warning">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SessionExpired