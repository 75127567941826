import {USER_DATA_SAVE} from '../actions/type'

let initialState={
    user:{}
}

export default function users (state=initialState, action){
    switch (action.type) {
        case USER_DATA_SAVE:
            return{...state, user:{...state.user, ...action.payload} }
    
        default:
            return state;
    }
}