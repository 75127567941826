import React, { useEffect, useState } from 'react';
import { getEventsList } from '../../redux/actions/events';
import eventsBaner from '../../assets/events.jpg';
import IsLoadingHOC from '../IsLoadingHOC';
import Pagination from '../common/Pagination';
import { useDispatch } from 'react-redux';
import ProductionsPage from './ProductionsPage';


function AllProductions(props) {
    const {isLoading, setLoading } = props
    const dispatch = useDispatch()
    const [postPerPage] = useState(6)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(null)
    const [events, setEvents] = useState([])


    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    useEffect(() => {
        getEventsListData(currentPage, postPerPage)
    }, [currentPage])


    const getEventsListData = async (currentPage, postPerPage) => {
        setLoading(true)
        await dispatch(getEventsList(currentPage, postPerPage))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1" && response.data.count) {
                        if (response.data && response.data.event.data) {
                            setEvents(response.data.event.data)
                            setTotalCount(response.data.count)
                        }
                    } else {
                        setEvents([])
                        setTotalCount(null)
                    }
                },
                (error) => {
                    console.log(error)
                    setLoading(false);
                    setTotalCount(null)
                    setEvents([])
                }
            )
    }

    return (
        <div>
            <section className="hm_banner">
                <div className="banner_box">
                    {/* <img src={eventsBaner} className="" alt="" /> */}
                </div>
            </section>
            <section className="wrap_con">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="leftWrap">
                                <div className="leftWrapin ">
                                    <div className="cardMain list-wrapper">
                                        <ProductionsPage events={events} isLoading = {isLoading} />
                                    </div>
                                    <Pagination postsPerPage={postPerPage} totalPosts={totalCount} paginate={paginate} currentPage={currentPage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default IsLoadingHOC(AllProductions)
