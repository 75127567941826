import React, { useEffect, useState } from 'react';
import defaultEvent from '../../assets/event.jpg';
import IsLoadingHOC from '../IsLoadingHOC';
import banner from '../../assets/logo-bannerSection.png';
import { useParams, useHistory } from 'react-router-dom';
import ScrollToTop from '../common/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { getEventDetailById } from '../../redux/actions/events';
import { setProductionID } from '../../redux/actions/events';
import { toast } from 'react-toastify';
import ConfirmJoinModel from './ConfirmJoinModel';
import ValidateProduction from './ValidateProduction';



function Rsvppage(props) {
    const { setLoading } = props
    const params = useParams();
    const history = useHistory();
    const [detailsData, setDetailsData] = useState({})
    const [model, setModel] = useState(false)
    const dispatch = useDispatch();
    const { token } = useSelector(state => state.auth);
    const [userId, setUserId] = useState(null)

    useEffect(() => {
        getProductionDetailData()
        const production_link = params.id
        let result = production_link.split('-')
        if (result && result.length > 1) {
            let user_id = result.pop()
            setUserId(parseInt(user_id))
        }
    }, [])


    const getProductionDetailData = async () => {
        const specialLink = params.id
        const production_id = specialLink.split('_').shift()
        setLoading(true)
        await dispatch(getEventDetailById(production_id))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setDetailsData(response.data.event)
                        dispatch(setProductionID(response.data.event.id))
                    } else {
                        toast.error('There is no data to display')
                        // history.push('/')
                    }
                },
                (error) => {
                    const errorMsg = error.response.data?.message || 'Sorry there is no data to display'
                    toast.error(errorMsg)
                    setLoading(false);
                    history.push('/')
                }
            )
    }

    const handleModel = () => {
        setModel(!model)
    }

    return (
        <div>
            <ScrollToTop />
            <section className="logoBanner">
                <div className="container">
                    <div className="logoBanner--wraper">
                        <img src={banner} alt="" />
                    </div>
                </div>
            </section>
            <section className="wrap_con">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="leftWrap">
                                <div className="leftWrapin">
                                    <div className="articleMain">
                                        <h2 className="articleMain--title">{detailsData?.name}</h2>
                                        {/* <h3 className="articleMain--subTitle">Sub Title</h3> */}
                                        <div className="articleMain--info">
                                            <span className="articleMain--type">Production</span>
                                            {detailsData?.created_at && <span className="articleMain--date">{(() => {
                                                var d = new Date(detailsData?.created_at)
                                                return (d.toLocaleString('default', { month: 'long' }) + (d.toUTCString().substr(4, 3)) + ", " + (d.toUTCString().substr(12, 5)))
                                            })()}</span>}
                                        </div>
                                        <div className="articleMain--content">
                                            <div className=" col articleMain--thumbnail">
                                                {detailsData && detailsData.image_path && <img src={detailsData.image_path} alt="new" />}
                                            </div>
                                            <div className="articleMain--description">
                                                <p dangerouslySetInnerHTML={{ __html: detailsData?.discription }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {userId ?
                            < ValidateProduction detailsData={detailsData} userId={userId} /> :
                            <div className="col-lg-4">
                                {detailsData && detailsData.name && <div className="rightWrap">
                                    <div className="rightWrapinIn">
                                        <div className="sidebarMain">
                                            <h3 className="sidebarTitle sec_title">{detailsData?.status === "1" ? "Click on the button below to join Production" : "This Production is not available right now!."}</h3>
                                            <div>
                                                {/* <img src={defaultQRCode} alt="Item" /> */}
                                            </div>
                                            <h5 className="">
                                                {token ?
                                                    <button className="btn btn-info" disabled={detailsData?.status === "0"} onClick={handleModel} >Join the Production</button>
                                                    : <button className="btn btn-info" disabled={detailsData?.status === "0"} data-toggle="modal" data-target="#loginForm">Join the Production</button>
                                                }
                                            </h5>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        }
                    </div>
                </div>
            </section>
            {model && <ConfirmJoinModel model={model} detailsData={detailsData} handleModel={handleModel} />}
        </div>
    )
}
export default IsLoadingHOC(Rsvppage)
