import React from 'react';
import { forgotpassword } from '../../redux/actions/auth';
import { useDispatch} from 'react-redux';
import IsLoadingHOC from '../IsLoadingHOC';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';


function ForgotPassword(props) {
    const { isLoading, setLoading } = props
    const dispatch = useDispatch();
    
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit: async values => {
            let fieldValues = values
            setLoading(true)
            await dispatch(forgotpassword(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.success === true || response.status == "1" ) {
                            toast.success('Please check your email for a password reset link')
                            formik.resetForm();
                        } else {
                            toast.error(`User doesn't exist!`)
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                    }
                )
                .catch(error => console.log(error))
        },
    });

    return (
        <>
            <div className="modal modalTwo common_form fade" id="forgotPass" role="dialog" aria-labelledby="forgotPassTitle"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="login_Sign">
                                <div className="loginSign_right">
                                    <div className="loginSign_wraper">
                                        <h2 className="font50 sec_title">Cannabis Capitol Reset password</h2>
                                        <form onSubmit={formik.handleSubmit} className="form_logSign">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input className="form-control" type="email" placeholder="Email address"
                                                    required
                                                    name="email"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>
                                            <button type="submit" className="btn form_logSignSubmit">{isLoading ? 'Loading..' : 'Submit'}</button>
                                        </form>
                                        <div>
                                            <h5>&nbsp;<button type="button" className="close" data-dismiss="modal" data-toggle="modal" data-target="#loginForm">Login</button></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default IsLoadingHOC(ForgotPassword)
