import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { joinTheEvent } from '../../redux/actions/events';
import { toast } from 'react-toastify';
import IsLoadingHOC from '../IsLoadingHOC';
import IsLoggedinHOC from '../IsLoggedinHOC';
import { getPrivacyPolicy } from '../../redux/actions/events';





function ConfirmJoinModel(props) {
    const { isLoading, setLoading, model, handleModel, detailsData } = props
    const { event_id } = useSelector(state => state.event)
    const [isAccepted, setAccepted] = useState(false)
    const dispatch = useDispatch();
    const history = useHistory();
    const { token, user } = useSelector(state => state.auth)
    const { name, id } = detailsData ? detailsData : {}
    const [privacyPolicy, setPrivacyPolicy] = useState(null)


    useEffect(() => {
        getPrivacyPolicyData()
    }, [])


    const getPrivacyPolicyData = async () => {
        setLoading(true)
        await dispatch(getPrivacyPolicy(id))
            .then(
                response => {
                    setLoading(false);
                    if (response.status === "1") {
                        setPrivacyPolicy(response.data.privacy_policy)
                    }
                },
                (error) => {
                    console.log(error)
                    setLoading(false);
                }
            )
    }

    const handleJoinProduction = async () => {
        if (event_id && user.id && token) {
            let eventId = parseInt(event_id)
            const payload = { event_id: eventId, user_id: user.id }
            setLoading(true)
            await dispatch(joinTheEvent(payload))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == '1') {
                            toast.success('QR code has been sent to your Email address')
                            history.push('/RSVP')
                        } else {
                            toast.error(response?.message)
                            history.push('/RSVP')
                            handleModel()
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response?.data?.message)
                    }
                )
        }
    }

    const buttonStyle = {
        minWidth: '70px'
    }
    // const scroll_style = {
    //     overflowY: 'scroll',
    //     height: '550px'
    // };

    return (
        <div id="myModal" className={`modal fade policy--modal ${model ? "show" : "hide"}`} style={{ display: model ? "block" : "none", overflowY: 'scroll' }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">The Privacy Policy</h4>
                        <button type="button" className="close" onClick={() => handleModel()} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="modal-body-ct">
                            {privacyPolicy ?
                                <div
                                    dangerouslySetInnerHTML={{ __html: privacyPolicy }}>
                                </div> :
                                <div>
                                    <p>This is an Infused Productions INC DBA Infused Digital and <b>Cannabis Capitol</b> Production and private event.</p>
                                    <p> You are invited to a private filming to participate in the audience of Cannabis Capitol's <b>(“{name}”)</b> Live Production Filming! </p>
                                    <p>  This is an Infused Productions INC DBA Infused Digital and Cannabis Capitol Private Production. </p>
                                    <p> NO SALES OF ANY KIND ALLOWED OR ILLEGAL PRODUCTS OF ANY KIND ALLOWED INSIDE INFUSED PRODUCTIONS AT ANYTIME! </p>

                                    <p> Production : Video and Photoshoot located at 10541 Calle Lee, Suite 101, Los Alamitos, CA 90720 (the “Production”) </p>

                                    <p>  DATE/TIME OF PRODUCTION: Beginning the evening of, (Date of Signature) and ending at the conclusion of the Production. </p>

                                    <p>  ORGANIZERS: Cannabis Capitol <b>(“{name}”)</b> and Infused Productions DBA Infused Digital (“Infused” and together with the directors, officers, employees, volunteers, representatives, and agents of both Cannabis Capitol and Infused, the activity or production holders, activity or event sponsors, and activity or event volunteers, collectively the “Organizers”). </p>

                                    <p>  Release of Liability - I HEREBY ASSUME ALL OF THE RISKS OF PARTICIPATING AND/OR VOLUNTEERING IN THE PRODUCTION including by way of example and not limitation, any risks that may arise from negligence or carelessness on the part of the Organizers, from dangerous or defective equipment or property owned, maintained, or controlled by them, or because of their possible liability without fault. I certify that there are no health-related reasons or problems which preclude my participation in this Production. I acknowledge that this Release of Liability and Photography Release Waiver will be used by the Organizers of the Production in which I may participate, and that it will govern my actions and responsibilities at said activity or Production. In consideration of my application and permitting me to participate in this Production, I hereby take action for myself, my executors, administrators, heirs, next of kin, successors, and assigns as follows: (A) I WAIVE, RELEASE, AND DISCHARGE from any and all liability, including but not limited to, liability arising from the negligence or fault of the Organizers, for my death, disability, personal injury, property damage, property theft, or actions of any kind which may hereafter occur to me including my traveling to and from this Production; (B) I INDEMNIFY, HOLD HARMLESS, AND PROMISE NOT TO SUE the Organizers from any and all liabilities or claims made as a result of participation in the Event, whether caused by the negligence of release or otherwise. I acknowledge that the Organizers are NOT responsible for the errors, omissions, acts, or failures to act of any party or entity conducting a specific event or activity on behalf of the Organizers. </p>

                                    Photography/Use of Image
                                    <p>  I understand that at this production or related activities, I may be photographed and/or videoed. I agree to allow my photo, video, or film likeness to be used for any legitimate purpose by Organizers, producers, sponsors, organizers, and assigns. I hereby authorize Organizers, to publish photographs/ video/film taken of me at the Production, and my name and likeness, for use in the Organizer’s print, online and video-based marketing materials, as well as other Organizer publications. I hereby release and hold harmless the Organizers from any reasonable expectation of privacy or confidentiality associated with the images specified above. I further acknowledge that my participation is voluntary and that I will not receive financial compensation of any type associated with the taking or publication of these photographs/video/ film or participation in company marketing materials or other Organizer publications. I acknowledge and agree that publication of said photographs/video/film confers no rights of ownership or royalties whatsoever. I hereby release the Organizers and any third parties involved in the creation or publication of marketing materials, from liability for any claims by me or any third party in connection with my participation. </p>
                                    <p> This Release of Liability and Photography Release Waiver shall be construed broadly to provide a release and waiver to the maximum extent permissible under applicable law. I CERTIFY THAT I HAVE READ THIS DOCUMENT, AND I FULLY UNDERSTAND ITS CONTENT. I AM AWARE THAT THIS IS A RELEASE OF LIABILITY AND A CONTRACT AND I SIGN IT OF MY OWN FREE WILL. </p>
                                </div>
                            }
                            <div className="form-group form-check">
                                <input type="checkbox" style={{ width: '17px', height: '17px' }} className="form-check-input" onChange={() => setAccepted(!isAccepted)} />
                                <label className="form-check-label">{ }I have read and agree to <b>The Privacy Policy</b>, Terms of service and community guidelines.</label>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" style={buttonStyle} className="btn btn-secondary" onClick={() => handleModel()} data-dismiss="modal">Close</button>
                        <button type="button" disabled={!isAccepted} style={buttonStyle} className="btn btn-success" onClick={handleJoinProduction} >{isLoading ? 'Processing..' : 'Continue'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(ConfirmJoinModel))