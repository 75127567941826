export const USER_DATA_SAVE = "USER_DATA_SAVE";
export const POSTS_DATA = "POSTS_DATA";
export const NEWS_DATA = "NEWS_DATA";
export const TAGS_NEWS_DATA = "TAGS_NEWS_DATA";
export const CATEGORY_NEWS_DATA = "CATEGORY_NEWS_DATA";
export const FEATURED_POST = "FEATURED_POST";
export const SPECIFIC_ARTICLE = "SPECIFIC_ARTICLE"; 
export const CATEGORY_LINK = "CATEGORY_LINK";
export const VIDEO_DATA_LIST = "VIDEO_DATA_LIST";
export const RELATED_VIDEO_POST = "RELATED_VIDEO_POST"
export const SPECIFIC_VIDEO = "SPECIFIC_VIDEO"
export const ADS_POSTS = "ADS_POSTS"