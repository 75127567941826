
let initialState = {
    event_id: null
}

export default function event (state = initialState, action) {
    switch (action.type) {
        case 'SAVE_EVENT_ID':
            return { ...state, event_id: action.payload }
        default:
            return state;
    }
}