import React, { useEffect, useState } from 'react';
import { getBannerImage } from '../../redux/actions/events';
import { useDispatch } from 'react-redux';
import IsLoadingHOC from '../IsLoadingHOC';
import currentProduction from '../../assets/current_production.jpg';



function SchedulePage(props) {
    const { setLoading } = props
    const dispatch = useDispatch()
    const [bannerImage, setBannerImage] = useState('')


    useEffect(() => {
        getBannerImageData()
    }, [])


    const getBannerImageData = async () => {
        setLoading(true)
        await dispatch(getBannerImage())
            .then(
                response => {
                     setLoading(false);
                    if (response.status === "1") {
                        setBannerImage(response.data.banner_image)
                    }
                },
                (error) => {
                    console.log(error)
                   setLoading(false);
                }
            )
    }



    return (

        <div className='production--banner'>
             <img src={bannerImage ?  bannerImage : currentProduction} alt="production-thumbnail" /> 
        </div>

    )
}

export default IsLoadingHOC(SchedulePage)