import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import IsLoadingHOC from '../IsLoadingHOC';
import axios from 'axios';
import { VIMEO_URL } from '../../config';



function MainPost(props) {
    const state = useSelector(state => state.posts.featuredPost);
    const limitDesc = 100

    const { setLoading } = props;
    const [recentVideo, setRecentVideo] = useState({})
    const [realTimeVideo, setRealTimeVideo] = useState("")

    const Token = process.env.REACT_APP_VIMEO_TOKEN;

    useEffect(async () => {
        setLoading(true);
        const response = await axios.get(`${VIMEO_URL}/videos`,
            {
                headers: {
                    Authorization: `bearer ${Token}`
                }
            })
        if (response) {
            setRecentVideo(response.data.data[0])
            let obj = response.data.data.find(x => !x.name.includes("RECAP"));
            setRealTimeVideo(obj.player_embed_url)
            setLoading(false);
        }
    }, []);


    return (
        <div className="newscard--wrap ">
            <div className="newscard--videoPlay">
                {realTimeVideo && (
                    <ReactPlayer url={realTimeVideo} className="player__video" controls />
                )}
            </div>
            <div className="newscard--postCont">
                {/* <h4 className="newscard--postCategory">{state.video?.category_name}</h4> */}
                <h2 className="newscard--postTitle">{recentVideo?.name}</h2>
                <span className="newscard--postDate"><i>
                    {(() => {
                        var d = new Date(recentVideo?.created_time)
                        return (d.toLocaleString('default', { month: 'long' }) + (d.toUTCString().substr(4, 3)) + ", " + (d.toUTCString().substr(12, 5)))
                    })()}
                </i>
                </span>
                <div className="newscard--postPara">
                {recentVideo.description === null ? "No description!!" : recentVideo.description}
                </div>
            </div>
            <Link to="/moreentertainment" className="newscard--linkDetails">More Videos</Link>
        </div>
    )
}


export default IsLoadingHOC(MainPost)
