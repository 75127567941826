import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { VIMEO_URL } from '../../config';

function VimeoRelatedVideos() {
    const [relatedVideo, setRelatedVideo] = useState([])
    const Token = process.env.REACT_APP_VIMEO_TOKEN;
    let location_url = window.location.pathname;
    useEffect(async () => {
        const response = await axios.get(`${VIMEO_URL}/videos`,
            {
                headers: {
                    Authorization: `bearer ${Token}`
                }
            })
        if (response) {
            const shuffled = response.data.data.sort(() => 0.5 - Math.random());
            setRelatedVideo(shuffled.slice(0, 3))
        }
    }, [setRelatedVideo, location_url]);

    return (
        <>
            <div className="news_listBottom">
                <ul className="newsList">
                    {relatedVideo?.map((item, index) => (
                        <li className="newsListItem" key={index}>
                            <NavLink to={`/playvideo${item?.uri}`} className="newsListItemLink">
                                <div className="intThumbnail">
                                    <img src={item?.pictures?.base_link} alt="" />
                                </div>
                                <div className="intDetails">
                                    <span className="newsTitle">{item?.name}</span>
                                </div>
                            </NavLink>
                        </li>
                    ))}
                    <div className="moreVideosLink">
                        <Link to={`/shows`}>More like this &gt;&gt;</Link>
                    </div>
                </ul></div>

        </>
    )
}

export default VimeoRelatedVideos
