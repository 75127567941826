import React, { useEffect, useState } from 'react'
import { getUserJoinedList } from '../../redux/actions/user';
import eventsBaner from '../../assets/events.jpg';
import defaultEvent from '../../assets/event.jpg';
import IsLoadingHOC from '../IsLoadingHOC'
import IsLoggedinHOC from '../IsLoggedinHOC';
import Pagination from '../common/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';


function JoinedProductions(props) {
    const { isLoading, setLoading } = props
    const limitDesc = 100;
    const dispatch = useDispatch()
    const [pageLimit] = useState(6)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(null)
    const [jointEvents, setJointEvents] = useState([])
    const { token, user } = useSelector(state => state.auth)

    useEffect(() => {
        getUserJoinedData()
    }, [currentPage])

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const getUserJoinedData = async () => {
        setLoading(true)
        await dispatch(getUserJoinedList(user.id, currentPage, pageLimit))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == '1' && response.count > 0) {
                        if (response.event && response.event.length > 0) {
                            setJointEvents(response.event)
                            setTotalCount(response.count)
                        }
                    }
                },
                (error) => {
                    toast.error('There is no data to display')
                    console.log(error)
                    setLoading(false);
                    setTotalCount(null)
                    setJointEvents([])
                }
            )
    }

    return (
        <div>
            <section className="hm_banner">
                <div className="banner_box">
                    {/* <img src={eventsBaner} className="" alt="" /> */}
                </div>
            </section>
            <section className="wrap_con">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="leftWrap">
                                <div className="leftWrapin ">
                                    <div className="cardMain list-wrapper">
                                        {jointEvents && jointEvents.length > 0 &&
                                            jointEvents.map((val) => (
                                                <div className="newscard list-item" key={val.id}>
                                                    <div className="newscard--wrap ">
                                                        <div className="newscard--videoPlay">
                                                            <Link to={`/joined-production-details/${val.id}`} >
                                                                <img src={val.image_path} className="newscard--postThum" alt="item" />
                                                            </Link>
                                                        </div>
                                                        <div className="newscard--postCont">
                                                            <h4 className="newscard--postCategory">Productiont</h4>
                                                            <h3 className="newscard--postTitle" >{val.name}</h3>
                                                            <span className="newscard--postDate"><i> {(() => {
                                                                var d = new Date(val.created_at)
                                                                return (d.toLocaleString('default', { month: 'long' }) + (d.toUTCString().substr(4, 3)) + ", " + (d.toUTCString().substr(12, 5)))
                                                            })()}
                                                                {/* by {val.user_name} */}
                                                            </i></span>
                                                            <div className="newscard--postPara">
                                                                {val.discription && val.discription?.length > limitDesc ?
                                                                    <div dangerouslySetInnerHTML={{ __html: val?.discription.substring(0, limitDesc) + '...' }} />
                                                                    :
                                                                    <div dangerouslySetInnerHTML={{ __html: val?.discription }} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <Link to={`/joined-production-details/${val.id}`} className="newscard--linkDetails">More Details</Link>
                                                    </div>
                                                </div>
                                            ))}
                                        {jointEvents.length === 0 && !isLoading &&
                                            <div className='data-notdisplay'>
                                                <h3 >There is no data to display...</h3>
                                            </div>
                                        }
                                    </div>
                                    <Pagination postsPerPage={pageLimit} totalPosts={totalCount} paginate={paginate} currentPage={currentPage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default IsLoadingHOC(IsLoggedinHOC(JoinedProductions))
