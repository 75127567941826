import React, { useState } from 'react';
import signUp from '../../assets/signup-bg.jpg';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { checkExistUserApi, register, sendOtpApi, verifyOtpApi } from '../../redux/actions/auth';
import { joinTheEvent } from '../../redux/actions/events';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import { countryCode, phoneRegExp } from './constants';


const userAccountSchema = Yup.object({
    password: Yup.string()
        .required("Password is required")
        .min(5, 'Password must be at least 5 characters'),

    confirm_password: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
})

function SingupForm() {
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState(null)
    const [isOther, setOther] = useState(false)
    const [showOtp, setShowOtp] = useState(false)
    const [otp, setOtp] = useState("")
    const [createPassword, setCreatePassword] = useState(false)
    const { event_id } = useSelector(state => state.event)
    const [activeStep, setActiveStep] = useState("step-1")



    const handleJoinProduction = async (user, token) => {
        if (event_id && user?.id && token) {
            let eventId = parseInt(event_id)
            const payload = { event_id: eventId, user_id: user.id }
            setLoading(true)
            await dispatch(joinTheEvent(payload))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status == '1') {
                            toast.success('QR code has been sent to your Email address')
                            history.push('/RSVP')
                        } else {
                            toast.error(response?.message)
                            history.push('/RSVP')
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response?.data?.message)
                    }
                )
        }
    }


    const formik = useFormik({
        initialValues: {
            fname: "",
            lname: "",
            phone: "",
            email: "",
            position: "",
            other_position: "",
            instagram_name: "",
            organization: "",
            insterested_status: "",
            invited_owner: "",
            dob: "",
            password: "",
            confirm_password: ""
        },
        validationSchema: userAccountSchema,
        onSubmit: async values => {
            let fieldValues = { ...values }
            let phoneNumber = fieldValues?.phone?.toString();
            if (phoneNumber[0] !== countryCode) {
                fieldValues.phone = `${countryCode}${phoneNumber}`;
            }
            setLoading(true)
            await dispatch(register(fieldValues))
                .then(
                    response => {
                        setLoading(false)
                        if (response.status === '1') {
                            const jwtToken = response.data.token || null
                            const userDetails = response.data.user || {}
                            toast.success("Registration completed successfully")
                            formik.resetForm()
                            setSelectedDate(null)
                            setCreatePassword(false)
                            setShowOtp(false)
                            setActiveStep("step-1")
                            handleJoinProduction(userDetails, jwtToken)
                            setOtp("")
                            hideModel();
                        } else {
                            toast.error(response.data?.error)
                        }
                    },
                    error => {
                        setLoading(false)
                        toast.error(error.response.data?.message)
                        console.log(error)
                    }
                )
                .catch(error => console.log(error))
        },
    });

    const handleChangeDate = (date, name) => {
        formik.setFieldValue(name, moment(date).format("YYYY-MM-DD"))
        setSelectedDate(date)
    }

    const handleCheckedValue = (e) => {
        const { value, name } = e.target
        formik.setFieldValue(name, value)
    }

    const validateStepTwo = () => {
        const phoneRegExp = /^(\+|\d)[0-9]{7,16}$/;
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!formik.values.phone) {
            return true
        }
        else if (!phoneRegExp.test(formik.values.phone)) {
            return true
        }
        else if (!formik.values.dob) {
            return true
        }
        else if (!formik.values.email) {
            return true
        }
        else if (!mailformat.test(formik.values.email)) {
            return true
        }
        else {
            return false
        }
    }

    const hideModel = () => {
        let login__modal = document.getElementById("signupForm");
        let login__overlay = document.getElementsByClassName("modal-backdrop")[0];
        login__modal.classList.remove("show");
        login__overlay.classList.remove("show");
        login__overlay.remove();
        login__modal.style.display = "none";
        document.getElementsByTagName("body")[0].classList.remove("modal-open");
    }

    const handleSelectPosition = (event) => {
        const { name, value } = event.target
        console.log(name, value)
        if (value == "9") {
            setOther(true)
            formik.setFieldValue(name, value)
        }
        else {
            setOther(false)
            formik.setFieldValue(name, value)
            formik.setFieldValue('other_position', '')
        }
    }

    const handleSendOtp = async () => {
        if (!formik.values.phone) {
            toast.error("Phone number is required!")
        }
        else if (!phoneRegExp.test(formik.values.phone)) {
            toast.error("Invalid Phone number!")
        }
        else {
            let payload = {}
            let phoneNumber = formik.values.phone.toString();
            if (phoneNumber[0] !== countryCode) {
                payload.phone = `${countryCode}${phoneNumber}`;
            }
            setLoading(true)
            await dispatch(sendOtpApi(payload))
                .then(
                    response => {
                        setLoading(false);
                        if (response.status == "1") {
                            const toastMsg = response.message || response?.data?.message
                            toast.success(toastMsg)
                            setShowOtp(true)
                        } else {
                            setShowOtp(false)
                            const error = response.message || response?.data?.error[0] || 'Somthing went wrong'
                            toast.error(error)
                        }
                    },
                    (error) => {
                        setLoading(false);
                        setShowOtp(false)
                        const errMsg = error.response?.data?.message || error.response?.data?.data?.error[0] || 'Somthing went wrong'
                        toast.error(errMsg)
                    }
                )
                .catch(error => {
                    setLoading(false);
                    setShowOtp(false)
                    const errMsg = error.response?.data?.message || error.response?.data?.data?.error[0] || 'Somthing went wrong'
                    toast.error(errMsg)
                })
        }
    }

    const verifyOtp = async () => {
        let payload = { code: otp }
        let phoneNumber = formik.values.phone.toString();
        if (phoneNumber[0] !== countryCode) {
            payload.phone = `${countryCode}${phoneNumber}`;
        }
        setLoading(true)
        await dispatch(verifyOtpApi(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        toast.success(response.message)
                        setCreatePassword(true)
                        setShowOtp(false)
                        setOtp("")
                    } else {
                        const error = response.message || response?.data?.error[0] || 'Somthing went wrong'
                        toast.error(error)
                    }
                },
                (error) => {
                    setLoading(false);
                    const errMsg = error.response?.data?.message || 'Somthing went wrong'
                    toast.error(errMsg)
                }
            )
            .catch(error => {
                setLoading(false);
                const errMsg = error.response?.data?.message || 'Somthing went wrong'
                toast.error(errMsg)
            })
    }

    const checkExistUser = async (step) => {
        let payload = { email: formik.values.email }
        let phoneNumber = formik.values.phone.toString();
        if (phoneNumber[0] !== countryCode) {
            payload.phone = `${countryCode}${phoneNumber}`;
        }
        setLoading(true)
        await dispatch(checkExistUserApi(payload))
            .then(
                response => {
                    setLoading(false);
                    if (response.status == "1") {
                        setActiveStep(step)
                    } else {
                        const error = response.message || response?.data?.error[0] || 'Somthing went wrong'
                        toast.error(error)
                    }
                },
                (error) => {
                    setLoading(false);
                    const errMsg = error.response?.data?.message || 'Somthing went wrong'
                    toast.error(errMsg)
                }
            )
            .catch(error => {
                setLoading(false);
                const errMsg = error.response?.data?.message || 'Somthing went wrong'
                toast.error(errMsg)
            })
    }

    const handleChangeNextStep = (step) => {
        if (step === "step-3") {
            checkExistUser(step)
        }
        else {
            setActiveStep(step)
        }
    }

    const handleChangePrevStep = (step) => {
        setActiveStep(step)
    }


    return (
        <div>
            <div className="modal modalOne fade" id="signupForm" role="dialog" aria-labelledby="signupFormTitle"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="login_Sign">
                                <div className="loginSign_left">
                                    <div className="loginSign_thumb">
                                        <img src={signUp} alt="Sign Up" />
                                    </div>
                                </div>
                                <div className="loginSign_right">
                                    <div className="loginSign_wraper">
                                        <h2 className="font50 sec_title">Welcome to Cannabis Capitol</h2>
                                        <h3>Create your account</h3>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="form_logSign">
                                                <div className={`step step-1 ${activeStep === "step-1" ? "active" : ""}`}>
                                                    <div className="form-group">
                                                        <label>First Name*</label>
                                                        <input className="form-control" type="text" placeholder="First name"
                                                            required
                                                            name="fname"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.fname}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Last Name*</label>
                                                        <input className="form-control" type="text" placeholder="Last name"
                                                            required
                                                            name="lname"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.lname}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Organization*</label>
                                                        <input className="form-control" type="text" placeholder="Organization"
                                                            required
                                                            name="organization"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.organization}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    <div className='btn--group'>
                                                        <button onClick={() => handleChangeNextStep("step-2")} disabled={!formik.values.fname || !formik.values.lname || !formik.values.organization} type="button" className="btn next-btn">Next</button>
                                                    </div>
                                                </div>
                                                <div className={`step step-2 ${activeStep === "step-2" ? "active" : ""}`}>
                                                    <div className="form-group">
                                                        <label>Phone*</label>
                                                        <div className="input-group">
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">{countryCode}</span>
                                                            </div>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Phone No."
                                                                required
                                                                name="phone"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.phone}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Date of Birth*</label>
                                                        <DatePicker className="form-control date-field ub-l"
                                                            placeholderText="MM/DD/YYYY"
                                                            dateFormat={'MMM/dd/yyyy'}
                                                            selected={selectedDate}
                                                            maxDate={new Date()}
                                                            onChange={date => handleChangeDate(date, "dob")}
                                                            dropdownMode="select"
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            adjustDateOnChange
                                                            autocomplete={false}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Email*</label>
                                                        <input
                                                            className="form-control"
                                                            type="email"
                                                            placeholder="Email address"
                                                            required
                                                            name="email"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.email}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    <div className='btn--group'>
                                                        <button type="button" onClick={() => handleChangePrevStep("step-1")} className="btn previous-btn">Prev</button>
                                                        <button type="button" onClick={() => handleChangeNextStep("step-3")} disabled={validateStepTwo()} className="btn next-btn">{isLoading ? "Processing" : "Next"}</button>
                                                    </div>
                                                </div>
                                                <div className={`step step-3 ${activeStep === "step-3" ? "active" : ""}`}>
                                                    <div className="form-group">
                                                        <label>Position in Industry*</label>
                                                        <select required className="form-control" style={{ height: '50px' }} name="position" value={formik.values.position} onChange={handleSelectPosition}>
                                                            <option value=''>Select position</option>
                                                            <option value='1'>Store owner</option>
                                                            <option value='2'>Brand owner</option>
                                                            <option value='3'>Budtender</option>
                                                            <option value='4'>Buyer</option>
                                                            <option value='5'>Exec/Management</option>
                                                            <option value='6'>Sales Rep</option>
                                                            <option value='7'>Brand Ambassador</option>
                                                            <option value='8'>Influencer/Content Creator</option>
                                                            <option value='10'>Store Customer</option>
                                                            <option value='9'>Other</option>
                                                        </select>
                                                    </div>
                                                    {isOther && <div className="form-group">
                                                        <label>Other Position?</label>
                                                        <input className="form-control" type="text" placeholder="Enter other position"
                                                            required
                                                            name="other_position"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.other_position}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>}
                                                    <div className="form-group">
                                                        <label>What is your Instagram handle?</label>
                                                        <input className="form-control" type="text" placeholder="Instagram name"
                                                            required
                                                            name="instagram_name"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.instagram_name}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Are you Interested in creating content with us?</label>
                                                        <div className="form-check-inline">
                                                            <label className="form-check-label">
                                                                <input className="form-check-input" type="checkbox"
                                                                    id="check1"
                                                                    value="1"
                                                                    name="insterested_status"
                                                                    checked={formik.values.insterested_status === "1"}
                                                                    onChange={handleCheckedValue}
                                                                    onBlur={formik.handleBlur}
                                                                />Yes
                                                            </label>
                                                        </div>
                                                        <div className="form-check-inline">
                                                            <label className="form-check-label" >
                                                                <input className="form-check-input" type="checkbox"
                                                                    id="check2"
                                                                    value="0"
                                                                    name="insterested_status"
                                                                    checked={formik.values.insterested_status === "0"}
                                                                    onChange={handleCheckedValue}
                                                                    onBlur={formik.handleBlur}
                                                                />NO
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Who invited you?</label>
                                                        <input className="form-control" type="text" placeholder="Who invited you?"
                                                            required
                                                            name="invited_owner"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.invited_owner}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </div>
                                                    <div className='btn--group'>
                                                        <button type="button" onClick={() => handleChangePrevStep("step-2")} className="btn previous-btn">Prev</button>
                                                        <button type="button" onClick={() => handleChangeNextStep("step-4")} disabled={!formik.values.position || !formik.values.instagram_name || !formik.values.insterested_status || !formik.values.invited_owner} className="btn next-btn">Next</button>
                                                    </div>
                                                </div>
                                                <div className={`step step-4 ${activeStep === "step-4" ? "active" : ""}`}>
                                                    {!showOtp && !createPassword &&
                                                        <div className="form-group">
                                                            <label>Phone Verification*</label>
                                                            <div className="input-group">
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">{countryCode}</span>
                                                                </div>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Phone No."
                                                                    required
                                                                    name="phone"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.phone}
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {showOtp && !createPassword &&
                                                        <div className="form-group">
                                                            <label>OTP Verification</label>
                                                            <OtpInput
                                                                inputType="number"
                                                                value={otp}
                                                                onChange={(e) => setOtp(e)}
                                                                numInputs={6}
                                                                renderSeparator={<span style={{ width: "5px" }}></span>}
                                                                renderInput={(props) => <input {...props} />}
                                                                shouldAutoFocus={true}
                                                                containerStyle={{
                                                                    justifyContent: "center",
                                                                    marginTop: "0em",
                                                                }}
                                                                inputStyle={{
                                                                    border: "1px solid #23945f",
                                                                    borderRadius: "8px",
                                                                    width: "10%",
                                                                    height: "35px",
                                                                    fontSize: "16px",
                                                                    color: "black",
                                                                    fontWeight: "400",
                                                                    caretColor: "blue",
                                                                    margin: "20px 8px 0 8px",
                                                                }}
                                                                focusStyle={{
                                                                    border: "1px solid #23945f",
                                                                    outline: "none",
                                                                }}
                                                            />
                                                        </div>
                                                    }

                                                    {createPassword && !showOtp &&
                                                        <>
                                                            <div className="form-group">
                                                                <label>Password*</label>
                                                                <input className="form-control" type='password' placeholder="Password"
                                                                    required
                                                                    name="password"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.password}
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                                {formik.touched.password && formik.errors.password ? (<span className="text-danger">{formik.errors.password}</span>) : null}

                                                            </div>
                                                            <div className="form-group">
                                                                <label>Confirm Password*</label>
                                                                <input className="form-control" type='password' placeholder="Confirm  Password"
                                                                    required
                                                                    name="confirm_password"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.confirm_password}
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                                {formik.touched.confirm_password && formik.errors.confirm_password ? (<span className="text-danger">{formik.errors.confirm_password}</span>) : null}
                                                            </div>
                                                        </>
                                                    }

                                                    <div className='btn--group'>
                                                        <button onClick={() => handleChangePrevStep("step-3")} type="button" className="btn previous-btn">Prev</button>
                                                        {!showOtp && !createPassword &&
                                                            <button type="button" disabled={!formik.values.phone || isLoading} onClick={handleSendOtp} className="btn submit-btn">
                                                                {isLoading ? 'Processing' : 'Send OTP'}
                                                            </button>
                                                        }
                                                        {showOtp && !createPassword &&
                                                            <button disabled={otp.length < 6 || isLoading} type="button" onClick={verifyOtp} className="btn submit-btn">
                                                                {isLoading ? 'Processing' : 'Verify OTP'}
                                                            </button>
                                                        }
                                                        {createPassword && !showOtp &&
                                                            <button type="submit" className="btn submit-btn"
                                                                disabled={!formik.values.password || !formik.values.confirm_password || isLoading}>
                                                                {isLoading ? 'Processing' : 'Submit'}
                                                            </button>
                                                        }
                                                    </div>
                                                    {showOtp && !createPassword &&
                                                        <div style={{ margin: "3em auto " }} className="d-flex justify-content-center text-center">
                                                            <p style={{ textAlign: "center", margin: "auto 1px  auto auto" }}>
                                                                Didn't recieve code ?
                                                            </p>
                                                            <button
                                                                disabled={isLoading}
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    border: "none",
                                                                    display: "block",
                                                                    margin: " auto auto  auto 0px",
                                                                    color: "#F6A94A",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={handleSendOtp}
                                                            >
                                                                Resend OTP
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                        {/* <h5>Already have an account? <button type="button" className="close" data-dismiss="modal"
                                        data-toggle="modal" data-target="#loginForm">
                                        Login Here
                                    </button> </h5> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingupForm
