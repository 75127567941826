import React, { useState } from 'react';
import logo from '../../assets/logo.png';
import { Link, useHistory } from "react-router-dom";
import SingupForm from './SingupForm';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPass';
import { logOut } from '../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import WarningPopup from './WarningPopup';

const Header = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user, token } = useSelector(state => state.auth)
    const [warningModel, setWarningModel] = useState(false)

    
    const handleLogOut = () => {
        dispatch(logOut());
       // history.push('/')
    }

    const handleRSVP = () => {
        history.push("/RSVP")
        setWarningModel(!warningModel)
    }

    const handleHidePopup = () => {
        setWarningModel(false)
    }

    return (
        <header className="main_nav">
            <div className="container">
                <nav className="navbar nav-new navbar-expand-lg navbar-light">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} className="" alt="" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                                    Entertainment
                                </a>
                                <div className="dropdown-menu">
                                    <Link className="dropdown-item" to="/shows">SHOWS <span className="sr-only">(current)</span></Link>
                                    <Link className="dropdown-item" to="/recaps">RECAPS</Link>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                                    Productions
                                </a>
                                <div className="dropdown-menu">
                                    <Link className="dropdown-item" to="/schedule">Schedule<span className="sr-only">(current)</span></Link>
                                    <a className="dropdown-item"  href="#" onClick={handleRSVP} >RSVP<span className="sr-only">(current)</span></a>
                                    {/* {token && user.role != '3' && <Link className="dropdown-item" to="/joined-productions">Joined Productions<span className="sr-only">(current)</span></Link>} */}
                                </div>
                            </li>
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                                Events
                                </a>
                                <div className="dropdown-menu">
                                    <Link className="dropdown-item"to="/events">View All Events<span className="sr-only">(current)</span></Link>
                                    <Link className="dropdown-item" to="/joint-events">View Joint Events</Link>
                                </div>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="/news">Community Voice</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/network">Network</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/creative">Creative Compound</Link>
                            </li>
                            <li className="nav-item">
                                {token ?
                                    <a className="nav-link"  href="#" onClick={handleLogOut}>Logout</a> :
                                    <a className="nav-link"  href="#" data-toggle="modal" data-target="#loginForm">Login</a>
                                }
                            </li>
                            {!token && <li className="nav-item sign_upNav">
                                <a className="nav-link" data-toggle="modal" href="#" data-target="#signupForm">Sign Up</a>
                            </li>}

                            {/* <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Dropdown
                  </Link>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to="#">Lorem Ipsum</Link>                    
                    <Link className="dropdown-item" to="#">Lorem Ipsum</Link>                    
                    <Link className="dropdown-item" to="#">Lorem Ipsum</Link>                    
                  </div>
                </li> */}
                        </ul>
                    </div>

                </nav>
            </div>

            <SingupForm />
            <LoginForm />
            <ForgotPassword />
            {warningModel && <WarningPopup model={warningModel} handleHidePopup={handleHidePopup} />}
        </header>
    )
}

export default Header
