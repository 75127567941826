import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import IsLoadingHOC from './IsLoadingHOC'
import banner from '../assets/intertainment.png';
import HighJinx from './SubVideoContent/HighJinx';
import PatientChronical from './SubVideoContent/PatientChronical';
import LiftedLaughter from './SubVideoContent/LiftedLaughter';
import Potpolitics from './SubVideoContent/PotPolitics';
import PimpsNFolks from './SubVideoContent/PimpsNFolks';
import JokersWorld from './SubVideoContent/JokersWorld';
import { VIMEO_URL } from '../config';
import ScrollToTop from './common/ScrollToTop';
import HiLbc from './SubVideoContent/HiLbc';
import ShowMoreText from "react-show-more-text";
import Potbrothers from './SubVideoContent/Potbrothers';
import BudTender from './SubVideoContent/BudTender';

function VimeoShow(props) {
    const { setLoading } = props;
    const [recentVideo, setRecentVideo] = useState({})
    const [realTimeVideo, setRealTimeVideo] = useState("")

    const Token = process.env.REACT_APP_VIMEO_TOKEN;

    useEffect(async () => {
        setLoading(true);
        const response = await axios.get(`${VIMEO_URL}/videos`,
            {
                headers: {
                    Authorization: `bearer ${Token}`
                }
            })
        if (response) {
            setRecentVideo(response.data.data[0])
            let obj = response.data.data.find(x => !x.name.includes("RECAP"));
            setRealTimeVideo(obj.player_embed_url)
            setLoading(false);
        }
    }, [setRecentVideo]);



    return (
        <div>
            <ScrollToTop />
            <section className="hm_banner">
                <div className="banner_box">
                    <img src={banner} alt="Null" />
                </div>
            </section>
            <section className="wrap_con">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="intertainWraper">
                                <div className="intertainWraperIn">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="news_listBottom player__video__tab">
                                                <ul className="newsList">
                                                    <li className="newsListItem bigBannner">
                                                        <div className="intThumbnail">
                                                            <ReactPlayer url={realTimeVideo} className="player__video" controls />
                                                            <div className="showLive">
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="postTopRightWraper">
                                                {/* <div className="newslistTop">
                                                    <h2 className="newslistTop--title sec_title">SHOWS</h2>
                                                    <p className="newslistTop--content">
                                                        The shows on the Cannabis Capitol network features a variety of cannabis personalities and influencers.
                                                        {realTimeVideo === undefined ? "Daily live streams from Cannabis Capitol studios featuring various cannabis  personalities and influencers." : videoDetails}
                                                    </p>
                                                </div> */}
                                                <div className="p-3">
                                                    <ul className="newsList">
                                                        <li className="newsListItem">
                                                            <div className="intDetails">
                                                                <h3 style={{ color: '#23945f' }} className="sec_title">Most Recent</h3>
                                                                <div className="newscard--postPara">
                                                                    <ShowMoreText
                                                                        lines={5}
                                                                        more=" Show more"
                                                                        less="... Show less"
                                                                        expanded={false}
                                                                        truncatedEndingComponent={"..."}
                                                                    >
                                                                        {recentVideo.description === null ? "No description!!" : recentVideo.description}
                                                                    </ShowMoreText>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="intertainWraper" >
                                <div className="intertainWraperIn">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="newslistTop" >
                                                <h2 className="newslistTop--title sec_title">Shows</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <HighJinx />
                            <BudTender />
                            <PatientChronical />
                            <LiftedLaughter />
                            <Potpolitics />
                            <HiLbc />
                            <PimpsNFolks />
                            <JokersWorld />
                            <Potbrothers />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


export default IsLoadingHOC(VimeoShow)