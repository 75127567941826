import axios from "axios";
import { store } from '../redux/store';

export const API_URL = {
	baseUrl: "https://dev.cannabiscapitol.com" || "http://dev.cannabiscapitol.com"
	// baseUrl: "http://3.143.208.162"
};
export const YOUTUBE_TOKEN = {
	token: "AIzaSyDNdm6YPHl0a7Nbk7eSdlR_gWHiABSxki8" || "AIzaSyA5vw8_j4UOsUfhdQuIsSTlwbsR2QMZ9ZU" || "AIzaSyAO4NIsDbmDxrVFgLjmMDgHCiok5FNSjQA" || "AIzaSyBuSA06UhNMPCWlmAStHS_OhiUV1Y1cNlE"
}

export const VIMEO_URL = "https://api.vimeo.com/users/152424759/"

export const VIEMO_VIDEO_API_URL = "https://api.vimeo.com/videos/"




export const authAxios = () => {
    let token = store.getState('state').auth.token;
    return axios.create({
        baseURL: process.env.REACT_APP_APIURL,
        headers: {
            'Authorization' : `${token ? `Bearer ${token}` : null}`,
        },
    });
};

export const withoutAuthAxios = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_APIURL
    });
};