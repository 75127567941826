import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { VIMEO_URL } from '../../config'
import { PATIENT_CHRONICALS_RECAPS } from '../common/constants'

const PatientChronicalRecaps = () => {

    const [PatientChronicalRecaps, setPatientChronicalRecaps] = useState([])
    const Token = process.env.REACT_APP_VIMEO_TOKEN;

    useEffect(async () => {
        const PatientChronicalRecapsData = await axios.get(`${VIMEO_URL}/albums/${PATIENT_CHRONICALS_RECAPS}/videos`,
            {
                headers: {
                    Authorization: `bearer ${Token}`
                }
            })
        if (PatientChronicalRecapsData) {

            setPatientChronicalRecaps(PatientChronicalRecapsData.data.data.splice(0, 3))
        }
    }, [setPatientChronicalRecaps]);

    return (
        <>
            {PatientChronicalRecaps && PatientChronicalRecaps.length > 0 ?
                (< div className="intertainWraper" >
                    <div className="intertainWraperIn">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="newslistTop" >
                                    <h2 className="newslistTop--title sec_title">PATIENT CHRONICLES</h2>
                                    <Link className="newslistTop--readmore" to={`/videos/${PATIENT_CHRONICALS_RECAPS}`}>See More Videos &gt;&gt;</Link>
                                    {/* <p className="newslistTop--content" dangerouslySetInnerHTML={{ __html: item.description }} /> */}
                                </div>
                                <div className="news_listBottom">
                                    <ul className="newsList">
                                        {PatientChronicalRecaps && PatientChronicalRecaps?.map((item, index) => (
                                            <li className="newsListItem" key={index} >
                                                <Link to={`/playvideo${item.uri}`} className="newsListItemLink">
                                                    <div className="intThumbnail">
                                                        <img src={item?.pictures?.base_link} alt="" />
                                                    </div>
                                                    <div className="intDetails">
                                                        <span className="newsTitle">{item?.name.length > 15 ?
                                                            (
                                                                <><span dangerouslySetInnerHTML={{ __html: item?.name.substring(0, 20) + "..." }} /></>
                                                            ) :
                                                            <> <span dangerouslySetInnerHTML={{ __html: item?.name }} /></>
                                                        }</span>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >) : <div className="intertainWraper" >
                    <div className="intertainWraperIn">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="newslistTop" >
                                    <h2 className="newslistTop--title sec_title">PATIENT CHRONICLES</h2>
                                    <p className="newslistTop--content" >No video is present!!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>

    )
}

export default PatientChronicalRecaps