export const HIGH_JINX = "8879851";
export const HIGH_JINX_RECAPS = "9722459";
export const JOKERS_WORLD = "9452581";
export const POT_BROTHERS = "9777008";
export const POT_BROTHERS_RECAPS = "9777013";
export const JOKERS_WORLD_RECAPS = "9724907";
export const LIFTED_LAUGHTER = "9628294";
export const LIFTED_LAUGHTER_RECAPS = "9730329";
export const LIVE_PRODUCTIONS = "9259746";
export const PATIENT_CHRONICALS = "9259740";
export const PATIENT_CHRONICALS_RECAPS = "9730325";
export const HI_LBC = "9628278";
export const HI_LBC_RECAPS = "9725310";
export const PIMPS_N_FOLKS = "9575061";
export const PIMPS_N_FOLKS_RECAPS = "9725243";
export const POT_POLITICS = "9628273";
export const BUD_TENDER = "10245813";
export const BUD_TENDER_RECAPS = "10161827";
export const POT_POLITICS_RECAPS = "9725338";
export const EVENTS = "9869509";


export const countryCode = process.env.REACT_APP_COUNTRY_CODE || "+1"
export const phoneRegExp = /^[0-9]{10}$/;


