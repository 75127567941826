import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import defaultEvent from '../../assets/event.jpg';
import { setProductionID } from '../../redux/actions/events';
import { useDispatch, useSelector } from 'react-redux';


function ProductionsPage({ events, isLoading }) {
    const { user } = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const limitDesc = 100;

    useEffect(() => {
        dispatch(setProductionID(null))
    }, [])


    return (
        <>
            {events.sort((a, b) => b.id < a.id ? - 1 : 1).map((val) => (
                <div className="newscard list-item" key={val.id}>
                    <div className="newscard--wrap ">
                        <div className="newscard--videoPlay">
                            {user && user.role == '3' ?
                                <img src={val.image_path} className="newscard--postThum" alt="item" />
                                :
                                <Link to={{
                                    pathname: `/RSVP/${val.slug}`,
                                    state: { productionId: val.id }
                                }} >
                                    <img src={val.image_path} className="newscard--postThum" alt="item" />
                                </Link>}
                        </div>
                        <div className="newscard--postCont">
                            <h4 className="newscard--postCategory">Production</h4>
                            <h3 className="newscard--postTitle" >{val.name}</h3>
                            <span className="newscard--postDate"><i> {(() => {
                                var d = new Date(val.created_at)
                                return (d.toLocaleString('default', { month: 'long' }) + (d.toUTCString().substr(4, 3)) + ", " + (d.toUTCString().substr(12, 5)))
                            })()}
                                {/* by {val.user_name} */}
                            </i></span>
                            <div className="newscard--postPara">
                                {val.discription && val.discription?.length > limitDesc ?
                                    <div dangerouslySetInnerHTML={{ __html: val?.discription.substring(0, limitDesc) + '...' }} />
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: val?.discription }} />
                                }
                            </div>
                        </div>
                        {user && user.role == '3' ?
                            <button className="newscard--linkDetails">More Details</button> :
                            <Link className="newscard--linkDetails"
                                to={{
                                    pathname: `/RSVP/${val.slug}`,
                                    state: { productionId: val.id }
                                }}
                            >More Details</Link>}
                    </div>
                </div>
            ))}
            {events.length === 0 && !isLoading &&
                <div className='data-notdisplay'>
                    <h3 >There is no data to display...</h3>
                </div>
            }
        </>
    )
}
export default ProductionsPage
